html {
  background: blanchedalmond;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
html,
body {
  padding: 0;
  margin: 0;
}

body {
  position: relative;
  height: 100vh;
  box-sizing: border-box;
}

canvas {
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.dg.ac {
  z-index: 2 !important;
}

.links {
  font-size: 0;
  position: absolute;
  margin: 0.5rem;
  bottom: 0;
  right: 0;
  z-index: 2;
}

.links a {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  transform: scale(1);
  transition: transform 0.2s;
}

.links a:first-of-type {
  margin-right: 0.5rem;
}

@media (min-width: 600px) {
  .links {
    margin: 1rem;
  }

  .links a {
    width: 3rem;
    height: 3rem;
  }

  .links a:first-of-type {
    margin-right: 1rem;
  }
}

.links a:hover {
  transform: scale(1.1);
}

.links a:active {
  transform: scale(0.9);
}

.links img {
  width: 100%;
  height: 100%;
}
